<template>
  <div class="md:mb-0 mb-56">
    <div class="flex gap-4">
      <div class="sm:mt-0 mt-2 pricing w-full font-normal sm:border border-0 border-solid border-desired-dawn rounded-xl lg:px-6 lg:py-10 md:py-8 sm:p-4">
        <!-- Monthly Volume -->
        <div>
          <p class="mb-5 text-young-night sm:text-base text-sm">{{ $t('Insert your expected monthly volume to find out your prices') }}</p>
          <div class="flex items-center sm:gap-3 gap-2">
            <shipblu-input :labelPlaceholder="$t('Monthly Volume')" inputId="volume" type="number" @input="calculateCost()" v-model="volumePrice" class="sm:w-52 w-full">
              <p class="slot-item text-industrial-revolution">{{ $t(' Order') }}</p>
            </shipblu-input>
            <div class="flex-shrink-0 border-1.5 border-solid border-range-land rounded-full py-3 px-4">
              <p class="text-range-land font-medium leading-none">{{ (Number(getFormattedPrice(averageDiscount)) % 1 === 0 ? Number(getFormattedPrice(averageDiscount)).toFixed(0) : getFormattedPrice(averageDiscount).endsWith('0') ? Number(getFormattedPrice(averageDiscount)).toFixed(1) : Number(getFormattedPrice(averageDiscount)).toFixed(2)) + '% ' + $t('Average Discount') }}</p>
            </div>
          </div>
          <p v-if="!volumePrice" class="text-fluorescent-red sm:text-base text-xs mt-1">{{ $t('Monthly volume should be greater than 0') }}</p>
          <p class="text-sm mt-2">
            <span class="text-young-night">{{ $t('See all volume') + ' ' }}</span>
            <span @click="discountsModal = true" class="text-primary border-0 border-b border-solid border-primary cursor-pointer">{{ $t('discounts') }}</span>
          </p>
        </div>

        <!-- Order Type -->
        <div class="mt-6 sm:p-0 p-4 sm:border-0 border border-solid border-desired-dawn rounded-lg">
          <p class="mb-4 text-young-night sm:text-base text-sm">{{ $t('Select Order Type') }}</p>
          <div class="lg:flex flex-wrap grid grid-cols-2 sm:gap-3 gap-2">
            <div v-for="(item, index) in orderTypes" :key="index" class="h-full lg:w-52">
              <input v-model="typePrice" type="radio" :value="item" name="order type" :id="`type_${index}`">
              <label class="block text-sm h-full sm:px-4 px-3 sm:py-3 py-2" :for="`type_${index}`">
                <div class="flex items-center justify-between gap-2">
                  <p>{{ $t(item.name) }}</p>
                  <span class="icon material-symbols-rounded text-primary text-base">check</span>
                </div>
                <p class="mt-3 text-young-night">{{ getFormattedPrice(item.price, $i18n.locale) }}</p>
              </label>
            </div>
          </div>
        </div>

        <div class="sm:p-0 p-4 mt-6 sm:border-0 border border-solid border-desired-dawn rounded-lg">
          <!-- Number Of Packages -->
          <div>
            <p class="mb-4 sm:text-base text-sm">
              <span class="text-young-night">{{ $t('Select number of packages') + ' ' }}</span>
              <span class="text-industrial-revolution">{{ '(' + $t('You may ship up to 4 packages per order, each will be priced according to its size') + ')' }}</span>
            </p>
            <div class="lg:flex flex-wrap grid grid-cols-2 sm:gap-3 gap-2">
              <div v-for="(item, index) in packages" :key="index" class="h-full lg:w-52">
                <input :disabled="cashCollectionSelected" v-model="packagesPrice[index]" @click="selectPackages(index)" type="radio" :value="item" :name="`package_${index}`" :id="`package_${index}`">
                <label :class="`block text-sm h-full sm:px-4 px-3 sm:py-3 py-2 ${cashCollectionSelected && index > 0 && 'border-cold-morning text-silver'}`" :for="`package_${index}`">
                  <div class="flex items-center justify-between gap-2">
                    <p>{{ $t(item.name) }}</p>
                    <span class="icon material-symbols-rounded text-primary text-base">check</span>
                  </div>
                  <p :class="`mt-3 ${cashCollectionSelected && index > 0 ? 'text-silver' : 'text-young-night'}`">{{ (index > 0 ? '+ ' : '') + getFormattedPrice(item.price, $i18n.locale) }}</p>
                </label>
              </div>
            </div>
          </div>

          <!-- Package Sizes -->
          <div class="mt-6">
            <p class="mb-4 text-young-night sm:text-base text-sm">{{ $t('Select the size of each of your order packages') }}</p>
            <div class="lg:flex flex-wrap grid grid-cols-2 sm:gap-3 gap-2">
              <div v-for="(item, index) in packagesSizes" :key="index" class="h-full lg:w-52">
                <div :class="`border-1.5 border-solid ${cashCollectionSelected ? 'border-cold-morning' : item.count > 0 ? 'border-primary' : 'border-silver'} rounded-lg flex justify-between`">
                  <div :class="`text-sm ${cashCollectionSelected ? 'text-silver' : 'text-young-night'} sm:px-4 px-3 sm:py-3 py-2`">
                    <p>
                      <span :class="item.count > 0 && 'text-primary'">{{ $t(item.name) }}</span>
                      <span v-if="item.count > 0" class="text-primary inline">{{ ' (' + item.count + ')' }}</span>
                    </p>
                    <p class="mt-3">{{ getFormattedPrice(item.price, $i18n.locale) }}</p>
                  </div>
                  <div :class="`grid grid-cols-1 border-0 border-l border-solid ${cashCollectionSelected ? 'border-cold-morning' : 'border-silver'}`">
                    <button :disabled="numberOfPackages() >= packagesPrice.length || cashCollectionSelected" @click="changePackageCount(index, 'increase')" :class="`bg-transparent px-3 outline-none border-0 border-b border-solid ${cashCollectionSelected ? 'border-cold-morning' : 'border-silver'} cursor-pointer flex items-center justify-center`">
                      <span :class="`material-symbols-outlined text-xl text-primary ${(numberOfPackages() >= packagesPrice.length || cashCollectionSelected) ? 'text-silver' : 'text-primary'}`">add</span>
                    </button>
                    <button :disabled="numberOfPackages() <= 0 || item.count === 0 || cashCollectionSelected" @click="changePackageCount(index, 'decrease')" class="bg-transparent px-3 outline-none border-0 cursor-pointer flex items-center justify-center">
                      <span :class="`material-symbols-outlined text-xl ${(numberOfPackages() <= 0 || item.count === 0 || cashCollectionSelected) ? 'text-silver' : 'text-primary'}`">remove</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="numberOfPackages() < packagesPrice.length && !cashCollectionSelected" class="text-fluorescent-red sm:text-base text-xs mt-2">{{ numberOfPackages() + $t('/') + packagesPrice.length + ' ' + $t('package sizes are selected') }}</p>
          </div>
        </div>

        <!-- Destination -->
        <div class="mt-6 sm:p-0 p-4 sm:border-0 border border-solid border-desired-dawn rounded-lg">
          <p class="mb-4 sm:text-base text-sm">
            <span class="text-young-night">{{ $t('Select destination') + ' ' }}</span>
            <span class="text-industrial-revolution">{{ '(' + $t('Destination prices are based on pickup locations in Cairo and Giza') + ')' }}</span>
          </p>
          <div class="lg:flex flex-wrap grid grid-cols-2 sm:gap-3 gap-2">
            <div v-for="(item, index) in destinations" :key="index" class="h-full lg:w-52">
              <input v-model="destinationPrice" type="radio" :value="item" name="destination" :id="`destination_${index}`">
              <label class="block text-sm h-full sm:px-4 px-3 sm:py-3 py-2" :for="`destination_${index}`">
                <div class="flex items-center justify-between gap-2">
                  <p>{{ $t(item.name) }}</p>
                  <span class="icon material-symbols-rounded text-primary text-base">check</span>
                </div>
                <p class="mt-3 text-young-night">{{ getFormattedPrice(item.price, $i18n.locale) }}</p>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div @click="windowWidth < 768 ? cardExpanded = !cardExpanded : ''" 
        :class="`md:static fixed md:max-h-full ${cardExpanded ? 'max-h-full' : 'max-h-[270px]'} bottom-0 z-[1] right-0 lg:w-80 md:w-72 sm:w-[calc(100%-82px)] w-full bg-primary flex-shrink-0 md:rounded-xl rounded-t-xl lg:py-10 md:py-8 py-2 transition-all duration-300 ease-in-out`" 
        :style="windowWidth < 768 && !cardExpanded && 'background: linear-gradient(180deg, #1C5BFE 66.7%, rgba(28, 91, 254, 0.65) 110.48%), linear-gradient(0deg, #FFFFFF, #FFFFFF);'">
        <div class="md:hidden mb-7 border-2 w-20 m-auto rounded-full border-solid border-orochimaru"></div>
        <div class="h-full flex flex-col gap-2 justify-between">
          <div class="lg:px-6 sm:px-4 px-8 pb-7">
            <div class="text-center">
              <p class="text-white text-2xl font-bold">{{ $t('SingleBlu Plan') }}</p>
              <p class="mt-3 xl:w-4/5 m-auto text-eyeshadow sm:text-base text-sm">{{ $t('Your average shipping price based on your monthly volume') }}</p>
              <p class="mt-2 font-semibold text-white">
                <span class="text-5xl">{{ (Number(getFormattedPrice(typePrice.price)) + Number(getFormattedPrice(destinationPrice.price)) + Number(getFormattedPrice(selectedFlyerSizes.reduce((sum, { price }) => sum + Number(getFormattedPrice(price)), 0))) + Number(getFormattedPrice(packagesPrice.reduce((sum, { price }) => sum + Number(getFormattedPrice(price)), 0)))).toFixed(2) + ' '}}</span>
                <span class="text-lg">{{ $t('EGP') + ' / ' + $t('order') }}</span>
              </p>
            </div>
            <div class="mt-6 flex flex-col gap-5 text-stoic-white">
              <div v-for="(item, index) in packagesPrice" :key="index">
                <div class="mb-2 flex items-center gap-3">
                  <p class="flex-shrink-0 text-xs">{{ $t(item.name) }}</p>
                  <div class="w-full border-0 border-b border-solid border-c64-ntsc"></div>
                </div>
                <div class="leading-tight flex justify-between items-center">
                  <p class="text-sm">{{ $t('Base Price') }}</p>
                  <p class="text-xs">{{ getFormattedPrice(index === 0 ? typePrice.price : packages[index].price) }}</p>
                </div>
                <div class="flex justify-between items-center">
                  <template v-if="selectedFlyerSizes.length > index && selectedFlyerSizes[index].count > 0">
                    <p class="text-sm">{{ $t(`Package Size ${selectedFlyerSizes[index].name}`) }}</p>
                    <p class="text-xs">{{ getFormattedPrice(selectedFlyerSizes[index].price) }}</p>
                  </template>
                  <template v-else-if="!cashCollectionSelected">
                    <p class="text-sm">{{ $t('Package Size Unselected') }}</p>
                    <p class="text-xs">{{ $t('??') }}</p>
                  </template>
                </div>
              </div>
              <div>
                <div class="mb-2 flex items-center gap-3">
                  <p class="flex-shrink-0 text-xs">{{ $t('Destination') }}</p>
                  <div class="w-full border-0 border-b border-solid border-c64-ntsc"></div>
                </div>
                <div class="leading-tight flex justify-between items-center">
                  <p class="text-sm">{{ $t(destinationPrice.name) }}</p>
                  <p class="text-xs">{{ getFormattedPrice(destinationPrice.price) }}</p>
                </div>
              </div>
            </div>
            <p class="mt-8 text-white sm:w-full w-4/5 leading-tight">{{ $t('All prices are subject to all applicable taxes.') }}</p>
          </div>
          <div class="md:block hidden w-11/12 max-w-[275px] rtl:mr-auto">
            <img src="../../../assets/images/pages/lined-logo.svg" class="w-full" alt="logo">
          </div>
        </div>
      </div>
    </div>

    <div class="sm:mt-4 mt-6 flex sm:items-center sm:gap-3 gap-2 border border-solid border-desired-dawn rounded-xl sm:px-6 sm:py-5 p-4">
      <img class="w-6 h-6" src="@/assets/images/pages/light-2.svg" alt="light-icon">
      <p class="text-young-night sm:text-base text-sm">{{ $t('EGP 2.99 surcharge fee applies to these zones: Helwan, Future City, New Heliopolis City, Badr City, Madinaty, 10th of Ramadan, El Shourouk, and Obour.') }}</p>
    </div>

    <div class="sm:mt-4 mt-3 flex sm:items-center sm:gap-3 gap-2 border border-solid border-desired-dawn rounded-xl sm:px-6 sm:py-5 p-4">
      <img class="w-6 h-6" src="@/assets/images/pages/light-2.svg" alt="light-icon">
      <p class="text-young-night sm:text-base text-sm">{{ $t('0.5% fee will be applied to any COD collected or refunds processed with a minimum EGP 1 per order.') }}</p>
    </div>

    <shipblu-prompt
      class="discounts-modal"
      @close="closeModal"
      :active.sync="discountsModal"
      :title="$t('Volume Discounts')"
      :buttons-hidden="true">
      <div class="discounts-table">
        <table>
          <thead>
            <tr>
              <th class="sm:px-8 p-4">{{$t('For')}}</th>
              <th class="sm:px-8 p-4">{{$t('Discount')}}</th>
              <th class="sm:px-8 p-4">{{$t('Price')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in volumeDiscounts.slice(1)" :key="index">
              <td class="sm:px-8 px-4 py-1">{{ $t(item.for) }}</td>
              <td class="sm:px-8 px-4 py-1">{{ (item.discount * 100).toLocaleString('en') + '%' }}</td>
              <td class="sm:px-8 px-4 py-1">{{ getFormattedPrice(item.price, $i18n.locale) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="mt-2 text-industrial-revolution">{{ $t('All prices are subject to all applicable taxes.') }}</p>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import ShipbluInput from '../../../layouts/components/ShipbluInput.vue'

export default {
  data () {
    return {
      cardExpanded: false,
      averageDiscount: 0,
      averageShippingFee: 0,
      volumePrice: 500,
      discountsModal: false,
      typePrice: {},
      destinationPrice: {},
      packagesPrice: [],
      selectedFlyerSizes: [],
      volumeDiscounts: [
        {
          for: '0',
          discount: 0,
          price: 67.85,
          threshold: 0
        },
        {
          for: 'First 100 pkgs',
          discount: 0,
          price: 67.85,
          threshold: 100
        },
        {
          for: 'Next 50 pkgs',
          discount: 0.15,
          price: 57.67,
          threshold: 150
        },
        {
          for: 'Next 50 pkgs',
          discount: 0.25,
          price: 50.89,
          threshold: 200
        },
        {
          for: 'Next 2,300 pkgs',
          discount: 0.30,
          price: 47.50,
          threshold: 2500
        },
        {
          for: 'Next 2,500 pkgs',
          discount: 0.35,
          price: 44.10,
          threshold: 5000
        },
        {
          for: 'Next 5,000 pkgs',
          discount: 0.37,
          price: 42.75,
          threshold: 10000
        },
        {
          for: 'Next 4,000 pkgs',
          discount: 0.40,
          price: 40.71,
          threshold: 14000
        },
        {
          for: 'Next 3,000 pkgs',
          discount: 0.50,
          price: 67.85,
          threshold: 17000
        },
        {
          for: 'Next pkgs',
          discount: 0.3844,
          price: 41.77,
          threshold: 1000000
        }
      ],
      orderTypes: [],
      packages: [],
      packagesSizes: [],
      destinations: [],
      cashCollectionSelected: false
    }
  },
  watch: {
    'packagesPrice.length' (val) {
      if (val < this.numberOfPackages()) {
        this.packagesSizes.forEach((item, index) => { item.count = index === 0 ? 1 : 0 })
      }
      this.updatePackagesInCart()
    },
    typePrice (val) {
      if (val.name === ' Cash Collection') {
        this.cashCollectionSelected = true
        this.packagesPrice = [this.packages[0]]
        this.packagesSizes.forEach((item) => { item.count = 0 })
      } else {
        this.cashCollectionSelected = false
      }
      [this.packages[0].price, this.packages[1].price, this.packages[2].price, this.packages[3].price] = [0, 0.25, 0.2, 0.15]
      this.packages.forEach(item => { item.price = item.price * this.typePrice.price })
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    numberOfPackages () {
      return this.packagesSizes.reduce((sum, { count }) => sum + count, 0)
    },
    changePackageCount (packageIndex, type) {
      if (type === 'increase') {
        this.packagesSizes[packageIndex].count += 1
      } else {
        this.packagesSizes[packageIndex].count -= 1
      }
      this.packagesSizes = [...this.packagesSizes]
      this.updatePackagesInCart()
    },
    selectPackages (packageIndex) {
      this.packagesPrice = this.packages.slice(0, packageIndex + 1)
    },
    getFormattedPrice (price, locale) {
      return locale === 'en' ? `${this.$t('EGP')} ${price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}` : locale === 'ar' ? `${price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})} ${this.$t('EGP')}` : price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})
    },
    closeModal () {
      this.discountsModal = false
    },
    updatePackagesInCart () {
      this.selectedFlyerSizes = []
      this.packagesSizes.forEach((item) => {
        for (let i = 0; i < item.count; i++) {
          this.selectedFlyerSizes.push(item)
        }
      })
      this.selectedFlyerSizes = this.selectedFlyerSizes.sort((a, b) => a.id - b.id)
    },

    // Calculate the cost based on volume thresholds
    calculateCost (reset) {
      let totalCost = 0
      const basePrice = 67.85
      if ((this.volumePrice ? this.volumePrice : 1) >= this.volumeDiscounts[this.volumeDiscounts.length - 1].threshold) {
        this.averageShippingFee = 41.77
        this.averageDiscount = (basePrice - this.averageShippingFee) * 100 / basePrice
      } else {
        for (let i = 0; i < this.volumeDiscounts.length; i++) {
          if ((this.volumePrice ? this.volumePrice : 1) >= this.volumeDiscounts[i].threshold) {
            const lastThreshold = i > 0 ? this.volumeDiscounts[i - 1].threshold : 0
            totalCost += (this.volumeDiscounts[i].threshold - lastThreshold) * basePrice * (1 - this.volumeDiscounts[i].discount)
          } else {
            totalCost += ((this.volumePrice ? this.volumePrice : 1) - this.volumeDiscounts[i - 1].threshold) * basePrice * (1 - this.volumeDiscounts[i].discount)
            break
          }
        }
        this.averageShippingFee = totalCost / (this.volumePrice ? this.volumePrice : 1)
        this.averageDiscount = (basePrice - this.averageShippingFee) * 100 / basePrice
      }
      this.applyAdditions(reset)
    },
    applyAdditions (reset) {
      this.initializeAdditionalValues(reset)
      this.orderTypes.forEach(item => { item.price = item.price * this.averageShippingFee })
      this.packages.forEach(item => { item.price = item.price * this.typePrice.price })
      this.packagesSizes.forEach(item => { item.price = item.price * this.averageShippingFee })
      this.destinations.forEach(item => { item.price = item.price * (1 - (this.getFormattedPrice(this.averageDiscount) / 100)) })
    },
    initializeAdditionalValues (reset) {
      if (reset) {
        this.orderTypes = [
          {
            name: 'Delivery or Return',
            price: 1
          },
          {
            name: ' Exchange',
            price: 2
          },
          {
            name: ' Cash Collection',
            price: 0.5
          },
          {
            name: 'RTO',
            price: 0.9
          }
        ]
        this.packages = [
          {
            name: '1st Package',
            price: 0
          },
          {
            name: '2nd Package',
            price: 0.25
          },
          {
            name: '3rd Package',
            price: 0.2
          },
          {
            name: '4th Package',
            price: 0.15
          }
        ]
        this.packagesSizes = [
          {
            id: 4,
            name: 'Small',
            price: 0,
            count: 1
          },
          {
            id: 3,
            name: 'Medium',
            price: 0,
            count: 0
          },
          {
            id: 2,
            name: 'Large',
            price: 0.1,
            count: 0
          },
          {
            id: 1,
            name: 'X-Large',
            price: 0.15,
            count: 0
          }
        ]
        this.destinations = [
          {
            name: 'Cairo & Giza',
            price: 0
          },
          {
            name: 'Alexandria',
            price: 6.9
          },
          {
            name: 'Delta & Canal Regions',
            price: 9.2
          },
          {
            name: 'Assiut',
            price: 60
          },
          {
            name: 'North Cost',
            price: 70
          }
        ]
        this.typePrice = this.orderTypes[0]
        this.packagesPrice = [this.packages[0]]
        this.destinationPrice = this.destinations[0]
        this.selectedFlyerSizes = [this.packagesSizes[0]]
      } else {
        [this.orderTypes[0].price, this.orderTypes[1].price, this.orderTypes[2].price, this.orderTypes[3].price] = [1, 2, 0.5, 0.9];
        [this.packages[0].price, this.packages[1].price, this.packages[2].price, this.packages[3].price] = [0, 0.25, 0.2, 0.15];
        [this.packagesSizes[0].price, this.packagesSizes[1].price, this.packagesSizes[2].price, this.packagesSizes[3].price] = [0, 0, 0.1, 0.15];
        [this.destinations[0].price, this.destinations[1].price, this.destinations[2].price, this.destinations[3].price, this.destinations[4].price] = [0, 6.9, 9.2, 60, 70]
      }
    }
  },
  components: {
    ShipbluPrompt,
    ShipbluInput
  },
  created () {
    this.calculateCost(true)
  }
}
</script>

<style lang="scss">
.pricing {
  input[type=radio], input[type=checkbox] {
    display: none;
  }
  input[type=radio] + label, input[type=checkbox] + label {
    cursor: pointer;
    user-select: none;
    border: 1.5px solid #BFBFBF;
    color: #565656;
    border-radius: 8px;
    .icon {
      display: none;
    }
  }
  input[type=radio]:checked + label, input[type=checkbox]:checked + label {
    border: 1.5px solid #1C5BFE;
    color: #1C5BFE;
    background-color: transparent;
    box-shadow: none;
    .icon {
      display: block;
    }
  }
}
div.discounts-modal {
  .vs-dialog{
    max-width: 560px !important;
  }
  .discounts-table {
    overflow-x: auto;
    border: 1px solid #DCDCDC;
    border-radius: 6px;
    table {
      border-collapse: collapse;
      width: 100%;
      tr {
        &:first-child td {
          padding-top: 12px !important;
        }
        &:last-child td {
          padding-bottom: 12px !important;
        }
      }
      th {
        border-bottom: 1px solid #DFDFDF;
      }
      th, td {
        &:not(:last-child) {
          border-right: 1px solid #DFDFDF;
        }
        white-space: nowrap;
        text-align: left;
        color: #565656;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
</style>